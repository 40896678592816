import React from 'react';
function Header({ title }) {
  return (
    <header className="header position-fixed">
      <div className="row">
        <div className="col-auto">
          <a data-bs-toggle="sidebar" data-bs-target="#sidebar" className="btn btn-light btn-44 menu-btn">
            <i className="bi bi-list"></i>
          </a>
        </div>
        <div className="col align-self-center text-center">
          <div className="logo-small">
            {!title ? <img className="shadow-none " src="img/logo.png" alt="" /> : null}
            <h5>{!title ? 'InfoData' : title}</h5>
          </div>
        </div>
        <div className="col-auto">
          <a href="notifications.html" target="_self" className="btn btn-light btn-44">
            <i className="bi bi-bell"></i>
            <span className="count-indicator"></span>
          </a>
        </div>
      </div>
    </header>
  )
}
export default Header;