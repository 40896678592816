import React, { StrictMode } from 'react';

function LoginLayout({ children }) {
  return (
    <StrictMode>
      <div className="d-flex flex-column h-100" data-page="signin">
        <div className="d-flex flex-column h-100" data-page="signin">
          <main className="container-fluid h-100">
            <div className="row h-100 overflow-auto">
              <div className="col-12 text-center mb-auto px-0">
                <header className="header">
                  <div className="row">
                    <div className="col-auto"></div>
                    <div className="logo-small">
                      <img className="" src="./img/logo.png" alt="" />
                    </div>
                    <div className="col-auto"></div>
                  </div>
                </header>
              </div>
              {children}
              <div className="col-12 text-center mt-auto">
                <div className="row justify-content-center footer-info">
                  <div className="col-auto">
                    <p className="text-muted">JPSISTEMAS-BR </p>
                  </div>
                  <div className="col-auto ps-0">
                    <a href="#" className="p-1"><i className="bi bi-twitter"></i></a>
                    <a href="#" className="p-1"><i className="bi bi-google"></i></a>
                    <a href="#" className="p-1"><i className="bi bi-facebook"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </StrictMode>
  );
}

export default LoginLayout;
