import React, { StrictMode } from 'react';

function Chamados() {
  return (
    <StrictMode>
      <div className="row mb-4">
        <div className="col-6 col-md-4 col-lg-3">
          <div className="card mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-auto">
                  <div className="circle-small">
                    <div id="circleprogressone"></div>
                    <div className="avatar avatar-30 alert-primary text-primary rounded-circle">
                      <i className="bi bi-person-bounding-box"></i>
                    </div>
                  </div>
                </div>
                <div className="col-auto align-self-center ps-0">
                  <p className="small mb-1 text-muted">Andamento</p>
                  <p>2<span className="small"></span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-4 col-lg-3">
          <div className="card mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-auto">
                  <div className="circle-small">
                    <div id="circleprogresstwo"></div>
                    <div className="avatar avatar-30 alert-success text-success rounded-circle">
                      <i className="bi bi-person-fill"></i>
                    </div>
                  </div>
                </div>
                <div className="col-auto align-self-center ps-0">
                  <p className="small mb-1 text-muted">Finalizados</p>
                  <p>5<span className="small"></span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4 col-lg-6">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-auto">
                  <div className="avatar avatar-40 alert-danger text-danger rounded-circle">
                    <i className="bi bi-diagram-3-fill"></i>
                  </div>
                </div>
                <div className="col align-self-center ps-0">
                  <div className="row mb-2">
                    <div className="col">
                      <p className="small text-muted mb-0">Total Chamados</p>
                      <p>7</p>
                    </div>
                    <div className="col-auto text-end">
                      <p className="small text-muted mb-0">Mensal</p>
                      <p className="small">25</p>
                    </div>
                  </div>

                  <div className="progress alert-danger h-4">
                    <div className="progress-bar bg-danger w-50" role="progressbar" aria-valuenow="25" aria-valuemin="0"
                      aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div className="row mb-3">
        <div className="col text-center">
          <h6 className="title">Ocorrências</h6>
        </div>
      </div>
      <div className="row" >
        <div className="col-12 col-md-6 col-lg-4">
          <a href="blog-details.html" className="card mb-1">
            <div className="card-body">
              <div className="row">
                <div className="col-auto">
                  <div className="avatar avatar-60 shadow-sm rounded-10 coverimg">
                    <img src="img/news.jpg" alt="" />
                  </div>
                </div>
                <div className="col align-self-center ps-0">
                  <p className="text-color-theme mb-1">PERTUBAÇÃO DO SUCEGO ALHEIO</p>
                  <p className="text-muted size-12">PACARAIMA: avenida florestal, 40, Florestal, próximo ao comercio Super Dez</p>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="col-12 col-md-6 col-lg-4">
          <a href="blog-details.html" className="card mb-1">
            <div className="card-body">
              <div className="row">
                <div className="col-auto">
                  <div className="avatar avatar-60 shadow-sm rounded-10 coverimg">
                    <img src="img/news1.jpg" alt="" />
                  </div>
                </div>
                <div className="col align-self-center ps-0">
                  <p className="text-color-theme mb-1">Walmart news latest picks</p>
                  <p className="text-muted size-12">Get $10 instant as reward while your friend or invited member join
                    FiMobile</p>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="col-12 col-md-6 col-lg-4">
          <a href="blog-details.html" className="card mb-1">
            <div className="card-body">
              <div className="row">
                <div className="col-auto">
                  <div className="avatar avatar-60 shadow-sm rounded-10 coverimg">
                    <img src="img/news2.jpg" alt="" />
                  </div>
                </div>
                <div className="col align-self-center ps-0">
                  <p className="text-color-theme mb-1">Do share and Help us</p>
                  <p className="text-muted size-12">Get $10 instant as reward while your friend or invited member join
                    FiMobile</p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>

    </StrictMode>
  );
}

export default Chamados;
