import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export async function getCadastroId(token, id) {
    // Verifica se o token é fornecido
    const cadastroUrl = `${API_URL}/cadastros/edit/${(id)}`;
    const headers = {
        'Authorization': token
    };
    const response = await axios.get(cadastroUrl, { headers });
    return response.data;
}
export async function getCadastros(token, page = 1, search = '') {
    // Verifica se o token é fornecido
    const cadastroUrl = `${API_URL}/cadastros?page=${page}&search=${(search)}`;
    const headers = {
        'Authorization': token
    };
    const response = await axios.get(cadastroUrl, { headers });
    return response.data;
}
export async function upCadastro(cadastro, token) {
    console.log(cadastro);
    const consultaUrl = `${API_URL}/cadastros/edit`;
    const headers = {
        'authorization': token,
        'Content-Type': 'multipart/form-data'
    }
    const response = await axios.post(consultaUrl, cadastro, { headers });
    return response.data;
}
export const deleteCadastro = async (id, token) => {
    const cadastroUrl = `${API_URL}/cadastros/delete/${id}`;
    const headers = {
        'Authorization': token
    };
    const response = await axios.delete(cadastroUrl, { headers });
    return response.data;
};


