import React, { StrictMode } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';


function AdminLayout({ children, title }) {
    
    return (
        <StrictMode>
            <div className="view view-main">
                <Sidebar />
                <main className="h-100" style={{ minHeight: '910px', paddingTop: '85.7188px', paddingBottom: '70px' }}>
                    <Header title={title} />
                    <div className="main-container container">
                        {children}
                    </div>
                </main>
                <Footer />
            </div>
        </StrictMode>
    );
}

export default AdminLayout;
