import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export async function getHome(token) {
    // Verifica se o token é fornecido
    if (!token) {
        throw new Error('Token de autenticação é necessário');
    }
    const homeUrl = `${API_URL}/home`;
    const headers = {
        'authorization': token
    }


    try {
        // Faz a requisição para a API
        const response = await axios.get(homeUrl, { headers });
        return response.data;
    } catch (error) {
        // Tratamento de erros
        if (error.response) {
            // Erro de resposta da API
            const { status, data } = error.response;
            if (status === 401) {
                throw new Error('401: Token de autenticação inválido ou expirado');
            }
            throw new Error(`Erro na requisição: ${data.message || 'Erro desconhecido'}`);
        } else if (error.request) {
            // Erro na requisição, mas sem resposta
            throw new Error('Erro na requisição: Nenhuma resposta recebida do servidor');
        } else {
            // Erro ao configurar a requisição
            throw new Error(`Erro ao configurar a requisição: ${error.message}`);
        }
    }
}
