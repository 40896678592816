import React from 'react';
import { Link } from 'react-router-dom';
function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <ul className="nav nav-pills nav-justified">
          <li className="nav-item">
            <Link className="nav-link active" to="/home">
              <span>
                <i className="nav-icon bi bi-house"></i>
                <span className="nav-text">Home</span>
              </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/cadastros">
              <span>
                <i className="bi bi-people-fill"></i>
                <span className="nav-text">Cadastros</span>
              </span>
            </Link>
          </li>
          <li className="nav-item centerbutton">
            <div className="nav-link">
              <span className="theme-radial-gradient">
                <i className="close bi bi-x"></i>
                <img src="/img/fingerprint2.svg" style={{ width: '40px', height: '40px' }} className="nav-icon" alt="" />
              </span>
              <div className="nav-menu-popover justify-content-between">
                <Link to="/consulta" type="button" className="btn btn-lg btn-icon-text">
                  <i className="bi bi-zoom-in"></i><span>Consulta</span>
                </Link>

                <Link to="/pessoas" type="button" className="btn btn-lg btn-icon-text">
                  <i className="bi bi-person-bounding-box"></i><span>Pessoas</span>
                </Link>

                <Link to="/chamados" type="button" className="btn btn-lg btn-icon-text">
                  <i className="bi bi-headset"></i><span>Chamados</span>
                </Link>
              </div>
            </div>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/orcrim">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  className="bi bi-yin-yang" viewBox="0 0 16 16">
                  <path d="M9.167 4.5a1.167 1.167 0 1 1-2.334 0 1.167 1.167 0 0 1 2.334 0"></path>
                  <path
                    d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0M1 8a7 7 0 0 1 7-7 3.5 3.5 0 1 1 0 7 3.5 3.5 0 1 0 0 7 7 7 0 0 1-7-7m7 4.667a1.167 1.167 0 1 1 0-2.334 1.167 1.167 0 0 1 0 2.334">
                  </path>
                </svg>
                <span className="nav-text">Orcrim</span>
              </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/objetos">
              <span>
                <i className="bi bi-box"></i>
                <span className="nav-text">Objetos</span>
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  )
}
export default Footer;