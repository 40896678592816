import React, { StrictMode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getHome } from '../../services/HomeSevice';



function Home() {
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [user, setUser] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        getHome(token).then(response => {
            setUser(response);

        }).catch(err => {
            navigate('/');
        })
    }, [])


    return (
        <StrictMode>
            {
                error ?
                    <div className='alert alert-danger mt-2'>{error}</div>
                    : null
            }
            <div className="row mb-4">
                <div className="col-auto">
                    <div className="avatar avatar-50 shadow rounded-10">
                        <img src="img/user1.jpg" alt="" />
                    </div>
                </div>
                <div className="col align-self-center ps-0">
                    <h4 className="text-color-theme"><span className="fw-normal">Olá</span>, {user.nomeGuerra}</h4>
                    <p className="text-muted">{user ? user.areas_atuacao.sigla : 'carregando...'}: {user.email}</p>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-12">
                    <div className="card theme-bg text-center">
                        <div className="card-body">
                            <div className="row">
                                <div className="col align-self-center">
                                    <h1>15% OFF</h1>
                                    <p className="size-12 text-muted">
                                        On every bill pay, launch offer get 5% Extra
                                    </p>
                                    <div className="tag border-dashed border-opac">
                                        BILLPAY15OFF
                                    </div>
                                </div>
                                <div className="col-6 align-self-center ps-0">
                                    <img src="img/offergraphics.png" alt="" className="mw-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <h6 className="title">Últimas Consultas</h6>
                </div>
                <div className="col-auto">
                    <a href="userlist.html" className="small">Ver Todas</a>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <swiper-container className="mySwiper" space-between="20"
                        slides-per-view="4">
                        <swiper-slide>
                            <a href="profile.html" className="card text-center">
                                <div className="card-body">
                                    <figure className="avatar avatar-50 shadow-sm mb-1 rounded-10">
                                        <img src="img/user4.jpg" alt="" />
                                    </figure>
                                    <p className="text-color-theme size-12 small">Nicolas</p>
                                </div>
                            </a>
                        </swiper-slide>
                        <swiper-slide>
                            <a href="profile.html" className="card text-center">
                                <div className="card-body">
                                    <figure className="avatar avatar-50 shadow-sm mb-1 rounded-10">
                                        <img src="img/user4.jpg" alt="" />
                                    </figure>
                                    <p className="text-color-theme size-12 small">Nicolas</p>
                                </div>
                            </a>
                        </swiper-slide>
                        <swiper-slide>
                            <a href="profile.html" className="card text-center">
                                <div className="card-body">
                                    <figure className="avatar avatar-50 shadow-sm mb-1 rounded-10">
                                        <img src="img/user4.jpg" alt="" />
                                    </figure>
                                    <p className="text-color-theme size-12 small">Nicolas</p>
                                </div>
                            </a>
                        </swiper-slide>
                        <swiper-slide>
                            <a href="profile.html" className="card text-center">
                                <div className="card-body">
                                    <figure className="avatar avatar-50 shadow-sm mb-1 rounded-10">
                                        <img src="img/user4.jpg" alt="" />
                                    </figure>
                                    <p className="text-color-theme size-12 small">Nicolas</p>
                                </div>
                            </a>
                        </swiper-slide>
                        <swiper-slide>
                            <a href="profile.html" className="card text-center">
                                <div className="card-body">
                                    <figure className="avatar avatar-50 shadow-sm mb-1 rounded-10">
                                        <img src="img/user4.jpg" alt="" />
                                    </figure>
                                    <p className="text-color-theme size-12 small">Nicolas</p>
                                </div>
                            </a>
                        </swiper-slide>
                    </swiper-container>

                </div>
            </div>
            <div className="row mb-4">
                <div className="col-12">
                    <div className="card shadow-sm">
                        <div className="card-body">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="darkmodeswitch" />
                                <label className="form-check-label text-muted px-2 " htmlFor="darkmodeswitch">Modo Escuro</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col">
                    <h6 className="title">Minhas Atualizações</h6>
                </div>
                <div className="col-auto">

                </div>
            </div>
            <div className="row mb-4">
                <div className="col-6 col-md-4 col-lg-3">
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-auto">
                                    <div className="circle-small">
                                        <div id="circleprogressone"></div>
                                        <div className="avatar avatar-30 alert-primary text-primary rounded-circle">
                                            <i className="bi bi-person-bounding-box"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto align-self-center ps-0">
                                    <p className="small mb-1 text-muted">Consultas</p>
                                    <p>60<span className="small"></span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-4 col-lg-3">
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-auto">
                                    <div className="circle-small">
                                        <div id="circleprogresstwo"></div>
                                        <div className="avatar avatar-30 alert-success text-success rounded-circle">
                                            <i className="bi bi-person-fill"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto align-self-center ps-0">
                                    <p className="small mb-1 text-muted">Cadastros</p>
                                    <p>85<span className="small"></span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-auto">
                                    <div className="avatar avatar-40 alert-danger text-danger rounded-circle">
                                        <i className="bi bi-diagram-3-fill"></i>
                                    </div>
                                </div>
                                <div className="col align-self-center ps-0">
                                    <div className="row mb-2">
                                        <div className="col">
                                            <p className="small text-muted mb-0">Vinculação Cadastro</p>
                                            <p>30</p>
                                        </div>
                                        <div className="col-auto text-end">
                                            <p className="small text-muted mb-0">Total</p>
                                            <p className="small">85</p>
                                        </div>
                                    </div>

                                    <div className="progress alert-danger h-4">
                                        <div className="progress-bar bg-danger w-50" role="progressbar" aria-valuenow="25" aria-valuemin="0"
                                            aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col">
                    <h6 className="title">Últimos Cadastros<br /><small className="fw-normal text-muted">24 Ago 2024</small>
                    </h6>
                </div>
                <div className="col-auto align-self-center">
                    <a href="transactions.html" className="small">Ver Todos</a>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-12 px-0">
                    <ul className="list-group list-group-flush bg-none">
                        <li className="list-group-item">
                            <div className="row">
                                <div className="col-auto">
                                    <div className="avatar avatar-50 shadow rounded-10 ">
                                        <img src="img/company4.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="col align-self-center ps-0">
                                    <p className="text-color-theme mb-0">RAIMUNDO NONATO DOS SANTOS</p>
                                    <p className="text-muted size-12">PACARAIMA</p>
                                </div>
                                <div className="col align-self-center text-end">
                                    <p className="mb-0">19/03/1992</p>
                                    <p className="text-muted size-12">PCC</p>
                                </div>
                            </div>
                        </li>

                        <li className="list-group-item">
                            <div className="row">
                                <div className="col-auto">
                                    <div className="avatar avatar-50 shadow rounded-10">
                                        <img src="img/company5.png" alt="" />
                                    </div>
                                </div>
                                <div className="col align-self-center ps-0">
                                    <p className="text-color-theme mb-0">RICARDO ZANCHETA DO CARMO</p>
                                    <p className="text-muted size-12">BOA VISTA</p>
                                </div>
                                <div className="col align-self-center text-end">
                                    <p className="mb-0">18/10/1985</p>
                                    <p className="text-muted size-12">N/A</p>
                                </div>
                            </div>
                        </li>

                        <li className="list-group-item">
                            <div className="row">
                                <div className="col-auto">
                                    <div className="avatar avatar-50 shadow rounded-10">
                                        <img src="img/company1.png" alt="" />
                                    </div>
                                </div>
                                <div className="col align-self-center ps-0">
                                    <p className="text-color-theme mb-0">Starbucks</p>
                                    <p className="text-muted size-12">Food</p>
                                </div>
                                <div className="col align-self-center text-end">
                                    <p className="mb-0">-18.00</p>
                                    <p className="text-muted size-12">Cash</p>
                                </div>
                            </div>
                        </li>

                        <li className="list-group-item">
                            <div className="row">
                                <div className="col-auto">
                                    <div className="avatar avatar-50 shadow rounded-10">
                                        <img src="img/company3.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="col align-self-center ps-0">
                                    <p className="text-color-theme mb-0">Walmart</p>
                                    <p className="text-muted size-12">Clothing</p>
                                </div>
                                <div className="col align-self-center text-end">
                                    <p className="mb-0">-105.00</p>
                                    <p className="text-muted size-12">Wallet</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col">
                    <h6 className="title">Últimas Ocorrências</h6>
                </div>
                <div className="col-auto align-self-center">
                    <a href="blog.html" className="small">Ver Todas</a>
                </div>
            </div>
            <div className="row" >
                <div className="col-12 col-md-6 col-lg-4">
                    <a href="blog-details.html" className="card mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-auto">
                                    <div className="avatar avatar-60 shadow-sm rounded-10 coverimg">
                                        <img src="img/news.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="col align-self-center ps-0">
                                    <p className="text-color-theme mb-1">PERTUBAÇÃO DO SUCEGO ALHEIO</p>
                                    <p className="text-muted size-12">PACARAIMA: avenida florestal, 40, Florestal, próximo ao comercio Super Dez</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-md-6 col-lg-4">
                    <a href="blog-details.html" className="card mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-auto">
                                    <div className="avatar avatar-60 shadow-sm rounded-10 coverimg">
                                        <img src="img/news1.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="col align-self-center ps-0">
                                    <p className="text-color-theme mb-1">Walmart news latest picks</p>
                                    <p className="text-muted size-12">Get $10 instant as reward while your friend or invited member join
                                        FiMobile</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-md-6 col-lg-4">
                    <a href="blog-details.html" className="card mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-auto">
                                    <div className="avatar avatar-60 shadow-sm rounded-10 coverimg">
                                        <img src="img/news2.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="col align-self-center ps-0">
                                    <p className="text-color-theme mb-1">Do share and Help us</p>
                                    <p className="text-muted size-12">Get $10 instant as reward while your friend or invited member join
                                        FiMobile</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

        </StrictMode>
    )
}
export default Home;