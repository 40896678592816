import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export async function getConsultas(token) {
     // Verifica se o token é fornecido
   
     const cadastroUrl = `${API_URL}/consulta`;
     const headers = {
         'authorization': token
     }
     const response = await axios.get(cadastroUrl, { headers });
     return response.data;
}
export async function newConsulta(newConsulta, token) {
    
    const consultaUrl = `${API_URL}/consulta/add-consulta`;
    const headers = {
        'authorization': token,
        'Content-Type': 'multipart/form-data'
    }
    const response = await axios.post(consultaUrl, newConsulta, { headers });
    return response.data;
}
