import React, { StrictMode, useState } from 'react';
import { login } from '../../services/AuthService';


function Login() {
  const [usuario, setUsuario] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  function onChangeInput(event) {
    if (event.target.id === 'usuario') {
      setUsuario(event.target.value);
    } else {
      setPassword(event.target.value)
    }
  }
  function onSubmit(event) {
    event.preventDefault();
    login(usuario, password)
      .then(response => {
        if (response) {
          localStorage.setItem('token', response.token);
          window.location = '/home';
        } else {
          setError('Usuário ou Senha Inválido!');
        }

      }).catch(err => {
        if (err.response) {
          // O servidor respondeu com um código de status diferente de 2xx
          setError(err.response.data.message);
        } else if (err.request) {
          // A requisição foi feita, mas nenhuma resposta foi recebida
          setError('Nenhuma resposta do servidor: '+ err.request);
        } else {
          // Outro erro ocorreu durante a configuração da requisição
          setError('Erro ao configurar requisição: '+ err.message);
        }


      })

  }
  return (
    <StrictMode>

      <div className="col-10 col-md-6 col-lg-5 col-xl-3 mx-auto align-self-center text-center py-4">
        <h1 className="mb-4 text-color-theme">Entrar</h1>
        <form className="was-validated needs-validation" action='#' onSubmit={onSubmit}>
          <div className="form-group form-floating mb-3 is-valid">
            <input type="text" className="form-control" id="usuario" placeholder="Usuário" onChange={onChangeInput} />
            <label className="form-control-label" htmlFor="email">Usuário</label>
          </div>

          <div className="form-group form-floating is-invalid mb-3">
            <input type="password" className="form-control " id="password" placeholder="Password" onChange={onChangeInput} />
            <label className="form-control-label" htmlFor="password">Senha</label>
            <button type="button" className="text-danger tooltip-btn" data-bs-toggle="tooltip"
              data-bs-placement="left" title="Enter valid Password" id="passworderror">
              <i className="bi bi-info-circle"></i>
            </button>
          </div>
          <p className="mb-3 text-center">
            <a href="/home" className="">
              Esqueci Minha Senha!
            </a>
          </p>

          <button type="submit" className="btn btn-lg btn-default w-100 mb-4 shadow">
            Logar
          </button>
          {
            error ?
              <div className='alert alert-danger mt-2'>{error}</div>
              : null
          }
        </form>
      </div>

    </StrictMode>
  )
}
export default Login;