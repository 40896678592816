import React, { StrictMode, useEffect, useState, useRef } from 'react';
import { getConsultas, newConsulta } from '../../services/ConsultasSevice';
import { useNavigate } from 'react-router-dom';
import { Share } from '@capacitor/share';
import { Camera, CameraResultType } from '@capacitor/camera';

function Consulta() {
  const navigate = useNavigate();
  const [fileUrl, setFileUrl] = useState(''); // Para armazenar a URL temporária
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [user, setUser] = useState('');
  const [imgPreview, setImgPreview] = useState(null); // Pré-visualização da imagem
  const [file, setFile] = useState(null); // Arquivo de imagem
  const consultaNome = useRef();
  const consultaVulgo = useRef();
  const consultaMae = useRef();
  const consultaDataNascimento = useRef();
  const consultaEndereco = useRef();
  const consultaEnderecoAbordagem = useRef();
  const consultaStatusCadastro = useRef('off');
  const token = localStorage.getItem('token');

  useEffect(() => {
    getConsultas(token)
      .then(userAgente => {
        setUser(userAgente);
      })
      .catch(err => {
        return navigate('/');
      });
  }, [navigate, token]);

  // Função para capturar a imagem usando a câmera
  const takePicture = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Uri,
      });
      setImgPreview(image.webPath); // Define a prévia da imagem
      setFileUrl(image.path); // Define a prévia da imagem
      const blob = await fetch(image.webPath).then(res => res.blob()); // Converte a imagem para blob
      setFile(new File([blob], 'imagem.jpg', { type: blob.type })); // Cria o arquivo para envio
    } catch (error) {
      console.error('Erro ao tirar a foto:', error);
    }
  };

  function onSubmit(event) {
    event.preventDefault();

    const formData = new FormData();
    formData.append('nome', consultaNome.current.value);
    formData.append('vulgo', consultaVulgo.current.value);
    formData.append('mae', consultaMae.current.value);
    formData.append('dataNascimento', consultaDataNascimento.current.value);
    formData.append('endereco', consultaEndereco.current.value);
    formData.append('enderecoAbordagem', consultaEnderecoAbordagem.current.value);
    formData.append('statusCadastro', consultaStatusCadastro.current.checked ? 'on' : 'off');
    formData.append('userId', user.id);
    formData.append('areaAtuacaoId', user.areaAtuacaoId);
    formData.append('img_path', "img_consulta");

    // Anexa a imagem, se houver
    if (file) {
      formData.append('img', file);
    }

    newConsulta(formData, token)
      .then(result => {
        if (result) {
          setError('');
          setSuccess('Consulta salva com sucesso!');
        } else {
          setError('Não foi possível salvar a consulta!');
          setSuccess('');
        }
      })
      .catch(err => {
        setError('Não foi possível salvar a consulta!');
        setSuccess('');
      });
  }

  const shareTextOnWhatsApp = async () => {
    // Criar uma mensagem com base nos campos preenchidos
    let message = '*Consulta*\n';

    if (consultaNome.current.value) {
      message += `*Nome:* ${consultaNome.current.value}\n`;
    } else {
      alert('Por favor, preencha pelo menos campo Nome antes de compartilhar.');
      return;
    }
    if (consultaVulgo.current.value) {
      message += `*Vulgo:* ${consultaVulgo.current.value}\n`;
    }
    if (consultaMae.current.value) {
      message += `*Mãe:* ${consultaMae.current.value}\n`;
    }
    if (consultaDataNascimento.current.value) {
      // Converte a data para o formato brasileiro
      const dataFormatada = new Date(consultaDataNascimento.current.value).toLocaleDateString('pt-BR', { timeZone: 'UTC' });
      message += `*DN:* ${dataFormatada}\n`;
    }
    if (consultaEndereco.current.value) {
      message += `*Endereço:* ${consultaEndereco.current.value}\n`;
    }
    if (consultaEnderecoAbordagem.current.value) {
      message += `*Endereço da Abordagem:* ${consultaEnderecoAbordagem.current.value}\n`;
    }

    if (!message.trim()) {
      alert('Por favor, preencha pelo menos um campo antes de compartilhar.');
      return;
    }
    await Share.share({
      title: 'CONSULTA',
      text: message,
      url: fileUrl || null, // Usar a URL temporária
      dialogTitle: 'Compartilhar imagem',
    });

  };
  return (
    <StrictMode>
      <form className="was-validated needs-validation" action='#' method="POST" encType="multipart/form-data" onSubmit={onSubmit}>
        <div className="row mb-4">
          <div className="col-12 text-center">
            {imgPreview ? (
              <img src={imgPreview} alt="Imagem da consulta" style={{ maxWidth: '100%', maxHeight: '200px' }} />
            ) : (
              <img src="img/infographic-scan.png" alt="" />
            )}

          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center mb-4">
            <button
              type="button"
              className="btn btn-default btn-lg mb-3 px-4 shadow-sm"
              onClick={takePicture} // Abre o seletor de arquivos
            >
              <i className="bi bi-camera mx-2"></i> Selecionar Imagem
            </button>

          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">

            <div className="form-group form-floating is-valid mb-1">
              <input type="text" ref={consultaNome} className="form-control " placeholder="Nome" name='nome' id="nome" required />
              <label className="form-control-label" htmlFor="contact">Nome *</label>
              <button type="button" className="text-color-theme tooltip-btn">
                <i className="bi bi-person-fill"></i>
              </button>
            </div>
            <div className="form-group form-floating is-valid mb-1">
              <input type="text" ref={consultaVulgo} className="form-control " placeholder="Vulgo" id="vulgo" />
              <label className="form-control-label" htmlFor="contact">Vulgo</label>
              <button type="button" className="text-color-theme tooltip-btn">
                <i className="bi bi-front"></i>
              </button>
            </div>
            <div className="form-group form-floating is-valid mb-1">
              <input type="text" ref={consultaMae} className="form-control " placeholder="Mãe" id="mae" />
              <label className="form-control-label" htmlFor="contact">Mãe</label>
              <button type="button" className="text-color-theme tooltip-btn">
                <i className="bi bi-file-earmark-person"></i>
              </button>
            </div>
            <div className="form-group form-floating is-valid mb-1">
              <input type="date" ref={consultaDataNascimento} className="form-control " placeholder="Data Nascimento" id="dataNascimento" />
              <label className="form-control-label" htmlFor="contact">DN</label>
            </div>
            <div className="form-group form-floating is-valid mb-1">
              <input type="text" ref={consultaEndereco} className="form-control " placeholder="Endereço" id="endereco" />
              <label className="form-control-label" htmlFor="contact">Endereço</label>
              <button type="button" className="text-color-theme tooltip-btn">
                <i className="bi bi-geo-alt-fill"></i>
              </button>
            </div>
            <div className="form-group form-floating is-valid mb-1">
              <input type="text" ref={consultaEnderecoAbordagem} className="form-control " placeholder="Endereço Abordagem" id="enderecoAbordagem" />
              <label className="form-control-label" htmlFor="contact">Endereço Abordagem</label>
              <button type="button" className="text-color-theme tooltip-btn">
                <i className="bi bi-geo-alt-fill"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card shadow-sm mb-4">
              <ul className="list-group list-group-flush bg-none">
                <li className="list-group-item">
                  <div className="row">
                    <div className="col-auto pr-0 align-self-center text-end">
                      <div className="form-check form-switch">
                        <input ref={consultaStatusCadastro} className="form-check-input" type="checkbox" id="statusCadastro" />
                        <label className="form-check-label" htmlFor="settingscheck1"></label>
                      </div>
                    </div>
                    <div className="col ps-0">
                      <h6 className="mb-1">Salvar Novo Cadastro</h6>
                      <p className="text-muted small">A consulta será salva como Novo Cadastro</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row h-100 ">

          <div className="col-12 mb-4">
            {error && <div className='alert alert-danger mt-2 text-center'>{error}</div>}
            {success && <div className='alert alert-success mt-2 text-center'>{success}</div>}
            <button type="submit" className="btn btn-default btn-lg w-100">SALVAR</button>
          </div>
        </div>

      </form>
      <div>
        <button className="btn btn-success btn-lg w-100" onClick={shareTextOnWhatsApp}>
          Compartilhar no WhatsApp
        </button>
      </div>


    </StrictMode >
  );
}

export default Consulta;
