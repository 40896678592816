import React, { StrictMode, useState, useEffect } from 'react';
import { getCadastros, deleteCadastro } from '../../services/CadastroSevice';
import { Link, useNavigate, useLocation } from 'react-router-dom';

function Cadastros() {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const query = new URLSearchParams(useLocation().search);
  const [cadastrosUltimos, setCadastrosUltimos] = useState([]);
  const [cadastros, setCadastros] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(parseInt(query.get('page')) || 1);
  const [searchQuery, setSearchQuery] = useState('');
  const token = localStorage.getItem('token');

  // Função para normalizar strings, removendo acentos
  const normalizeString = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
  };

  // Busca lista de cadastros com paginação
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { cadastrosPessoas } = await getCadastros(token, page, searchQuery);
        setCadastrosUltimos(cadastrosPessoas.ultimos_cad);
        setCadastros(cadastrosPessoas.rows);
        setTotalPages(cadastrosPessoas.totalPages);
      } catch (err) {
        if (err.response?.status === 401) navigate('/');
      }
    };
    fetchData();
  }, [navigate, token, page, searchQuery]);

  // Atualiza a busca e a paginação
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    setPage(1);
    navigate(`?page=1&search=${value}`);
  };

  // Paginação
  const changePage = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
      navigate(`?page=${newPage}`);
    }
  };

  // Filtra cadastro por nome, vulgo, mãe e endereço
  const filteredCadastros = cadastros.filter(({ nome, vulgo, mae, logradouro }) =>
    [nome, vulgo, mae, logradouro].some(field => 
      field && normalizeString(field).includes(normalizeString(searchQuery))
    )
  );

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Você tem certeza que deseja deletar este cadastro?");
    if (confirmDelete) {
      try {
        await deleteCadastro(id, token);
        setCadastros(prev => prev.filter(cadastro => cadastro.id !== id)); // Atualiza a lista
        setError('');
        setSuccess('Cadastro deletado com sucesso!');
      } catch (err) {
        setError('Não foi possível deletar o cadastro!');
        setSuccess('');
      }
    }
  };

  return (
    <StrictMode>
      <div className="form-group form-floating mb-3">
        <input
          type="text"
          className="form-control"
          id="search"
          placeholder="nome"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <label htmlFor="search">Buscar Cadastro</label>
      </div>

      <div className="col">
        <div className="row mb-3">
          <div className="col">
            <h6 className="title">Últimos Cadastros</h6>
          </div>
          <div className="col-auto">
            <Link to="/userlist" className="small">Ver Todas</Link>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <swiper-container className="mySwiper" space-between="10" slides-per-view="3">
              {cadastrosUltimos.map(cadastro => (
                <swiper-slide key={cadastro.id}>
                  <a href="#" className="card text-center p-0">
                    <div className="card-body">
                      <figure className="avatar avatar-80 shadow-sm mb-0 rounded-10">
                        <img
                          src={cadastro.img || 'path/to/default/image.jpg'}
                          alt={cadastro.nome}
                          style={{ width: '100%', objectFit: 'cover' }}
                        />
                      </figure>
                      <p className="text-color-theme size-12 small mt-2">{cadastro.nome}</p>
                    </div>
                  </a>
                </swiper-slide>
              ))}
            </swiper-container>
          </div>
        </div>
      </div>

      <div className="col">
        <div className="row mb-3">
          <div className="col align-self-center">
            <h6 className="title">Todos Cadastros</h6>
          </div>
          <div className="col-auto">
            <Link to="/cadastro" className="btn btn-44 btn-default shadow-sm">
              <i className="bi bi-plus"></i>
            </Link>
          </div>
        </div>
      </div>
      {error && <div className='alert alert-danger mt-2 text-center'>{error}</div>}
      {success && <div className='alert alert-success mt-2 text-center'>{success}</div>}
      <div className="row mb-4">
        <div className="col-12">
          <div className="card shadow-sm mb-4">
            <ul className="list-group list-group-flush bg-none">
              {filteredCadastros.slice(0, 10).map(cadastro => (
                <li className="list-group-item" key={cadastro.id}>
                  <div className="row">
                    <div className="col-auto align-self-center">
                      <div className="avatar avatar-50 shadow rounded-10">
                        <img src={cadastro.img} alt="Imagem" />
                      </div>
                    </div>
                    <div className="col w-100 align-self-center ps-0">
                      <p className="text-color-theme mb-0">{cadastro.nome}</p>
                      <p className=" size-12"><label className="text-muted">Mãe: {cadastro.mae} </label>
                        <i className="bi bi-circle-fill text-success mx-2"></i>
                        <i className="bi bi-exclamation-octagon-fill text-danger  mx-2"></i>
                        <i className="bi bi-exclamation-triangle-fill text-warning  mx-2"></i>
                        <i className="bi bi-lock-fill text-primary  mx-2"></i>
                      </p>
                    </div>
                    <div className="col-auto align-self-center text-end px-2">
                      <div className='btn-group'>
                        <Link to={`/cadastro-edit/${cadastro.id}`} className="btn btn-44 btn-light text-primary shadow-sm">
                          <i className="bi bi-pencil-square"></i>
                        </Link>
                        <button className="btn btn-44 btn-light text-danger shadow-sm" onClick={() => handleDelete(cadastro.id)}>
                          <i className="bi bi-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col text-center d-flex justify-content-between">
          <button
            className="btn btn-outline-primary"
            onClick={() => changePage(page - 1)}
            disabled={page === 1}
          >
            Anterior
          </button>
          <span className="mx-2">Página {page} de {totalPages}</span>
          <button
            className="btn btn-outline-primary"
            onClick={() => changePage(page + 1)}
            disabled={page === totalPages}
          >
            Próximo
          </button>
        </div>
      </div>
    </StrictMode>
  );
}

export default Cadastros;