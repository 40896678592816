import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://192.168.100.8:3001';

export async function login(usuario, password) {

    const loginUrl = `${API_URL}/login`;
    const response = await axios.post(loginUrl, { usuario, password },);
    return response.data;
}
export async function logout() {
    const logoutUrl = `${API_URL}/logout`;
    const response = await axios.post(logoutUrl);
    return response.data;
}