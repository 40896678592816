import React, { StrictMode, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getHome } from '../services/HomeSevice';
function Sidebar() {
  const navigate = useNavigate();
  const [user, setUser] = useState('');
  useEffect(() => {
    const token = localStorage.getItem('token');
    getHome(token).then(response => {
      setUser(response);
    }).catch(err => {
      if (err.response && err.response.status === 401) {
        return navigate('/');
      }
    })
  }, [])
  return (
    <div className="sidebar-wrap  sidebar-pushcontent" id='sidebar'>
      <div className="closemenu text-muted">Close Menu</div>
      <div className="sidebar dark-bg">
        <div className="row my-3">
          <div className="col-12 ">
            <div className="card shadow-sm bg-opac text-white border-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-auto">
                    <figure className="avatar avatar-44 rounded-15">
                      <img src="img/user1.jpg" alt="" />
                    </figure>
                  </div>
                  <div className="col px-0 align-self-center">
                    <p className="mb-1">{user.nome_guerra}</p>
                    <p className="text-muted size-12">1ªCIPMFRON</p>
                  </div>
                  <div className="col-auto">
                    <button className="btn btn-44 btn-light">
                      <i className="bi bi-box-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <ul className="nav nav-pills">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="index.html">
                  <div className="avatar avatar-40 rounded icon"><i className="bi bi-house-door"></i>
                  </div>
                  <div className="col">Início</div>
                  <div className="arrow"><i className="bi bi-chevron-right"></i></div>
                </a>
              </li>

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button"
                  aria-expanded="false">
                  <div className="avatar avatar-40 rounded icon"><i className="bi bi-person"></i></div>
                  <div className="col">Meus Dados</div>
                  <div className="arrow"><i className="bi bi-plus plus"></i> <i className="bi bi-dash minus"></i>
                  </div>
                </a>
                <ul className="dropdown-menu">
                  <li><a className="dropdown-item nav-link" href="profile.html">
                    <div className="avatar avatar-40 rounded icon"><i className="bi bi-calendar2"></i>
                    </div>
                    <div className="col">Perfil</div>
                    <div className="arrow"><i className="bi bi-chevron-right"></i></div>
                  </a></li>
                  <li><a className="dropdown-item nav-link" href="settings.html">
                    <div className="avatar avatar-40 rounded icon"><i className="bi bi-calendar-check"></i>
                    </div>
                    <div className="col">Configurações</div>
                    <div className="arrow"><i className="bi bi-chevron-right"></i></div>
                  </a></li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="chat.html" tabIndex="-1">
                  <div className="avatar avatar-40 rounded icon"><i className="bi bi-search"></i></div>
                  <div className="col">Consultas</div>
                  <div className="arrow"><i className="bi bi-chevron-right"></i></div>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="signin.html" tabIndex="-1">
                  <div className="avatar avatar-40 rounded icon"><i className="bi bi-file-earmark-text"></i>
                  </div>
                  <div className="col">Chamados</div>
                  <div className="arrow"><i className="bi bi-chevron-right"></i></div>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="notifications.html" tabIndex="-1">
                  <div className="avatar avatar-40 rounded icon"><i className="bi bi-person-bounding-box"></i>
                  </div>
                  <div className="col">Pessoas</div>
                  <div className="arrow"><i className="bi bi-chevron-right"></i></div>
                </a>
              </li>


              <li className="nav-item">
                <a className="nav-link" href="blog.html" tabIndex="-1">
                  <div className="avatar avatar-40 rounded icon"><i className="bi bi-disc-fill"></i></div>
                  <div className="col">Orcrim</div>
                  <div className="arrow"><i className="bi bi-chevron-right"></i></div>
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="style.html" tabIndex="-1">
                  <div className="avatar avatar-40 rounded icon"><i className="bi bi-collection-fill"></i>
                  </div>
                  <div className="col">Obejeto</div>
                  <div className="arrow"><i className="bi bi-chevron-right"></i></div>
                </a>
              </li>


              <li className="nav-item">
                <Link className="nav-link" to="/" tabIndex="-1">
                  <div className="avatar avatar-40 rounded icon"><i className="bi bi-box-arrow-right"></i>
                  </div>
                  <div className="col">Sair</div>
                  <div className="arrow"><i className="bi bi-chevron-right"></i></div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Sidebar;