import React, { StrictMode, useState, useEffect, useRef } from 'react';
import { upCadastro, getCadastroId } from '../../services/CadastroSevice';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Share } from '@capacitor/share';
import { Camera, CameraResultType } from '@capacitor/camera';
import InputMask from 'react-input-mask';
function Cadastros() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [cadastroPessoa, setCadastroPessoa] = useState([]);
  const token = localStorage.getItem('token');

  const [fileUrl, setFileUrl] = useState(''); // Para armazenar a URL temporária
  const [imgPreview, setImgPreview] = useState(null); // Pré-visualização da imagem
  const [file, setFile] = useState(null); // Arquivo de imagem
  // Busca lista de cadastros com paginação
  useEffect(() => {
    const fetchData = async () => {
      try {
        getCadastroId(token, id)
          .then(cadastro => {
            setCadastroPessoa(cadastro);
          })
          .catch(err => {
            if (err.response?.status === 404) navigate('/cadastros');
          });
      } catch (err) {
        if (err.response?.status === 401) navigate('/');
      }
    };
    fetchData();
  }, [navigate, token]);

  const takePicture = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Uri,
      });
      setImgPreview(image.webPath); // Define a prévia da imagem
      setFileUrl(image.path); // Define a prévia da imagem
      const blob = await fetch(image.webPath).then(res => res.blob()); // Converte a imagem para blob
      setFile(new File([blob], 'imagem.jpg', { type: blob.type })); // Cria o arquivo para envio
    } catch (error) {
      console.error('Erro ao tirar a foto:', error);
    }
  };
  function onSubmit(event) {
    event.preventDefault();
    if (file) {
      cadastroPessoa.img = file;
    }
    upCadastro(cadastroPessoa, token)
      .then(result => {
        if (result) {
          setCadastroPessoa(result);
          setError('');
          setSuccess('Cadastro Atualizado!');
        } else {
          setError('Não foi possível Atualizar o Cadastro!');
          setSuccess('');
        }
      })
      .catch(err => {
        setError('Não foi possível Atualizar o Cadastro!');
        setSuccess('');
      })
  }
  const valueInput = (e) => setCadastroPessoa({ ...cadastroPessoa, [e.target.name]: e.target.value });
  return (
    <StrictMode>
      <form className="was-validated needs-validation" action='#' method="POST" encType="multipart/form-data" onSubmit={onSubmit}>
        <div className="card shadow-sm mb-4">
          <div className="card-header">
            <div className="row text-center">
              <div className="col">
                <figure className="avatar avatar-160 rounded-10 border p-2" onClick={takePicture}>
                  {imgPreview ? (
                    <img src={imgPreview} alt="Imagem da consulta" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                  ) : (
                    <img src={cadastroPessoa.img} alt="" />
                  )}

                </figure>
              </div>

            </div>
          </div>
          <div className="card-body">
            <div className="col px-0 align-self-center text-center">
              <h3 className="mb-0 text-color-theme">{cadastroPessoa.nome}</h3>
              <p className="text-muted">
                {new Date(`${cadastroPessoa.dataNascimento}T00:00:00-04:00`).toLocaleDateString('pt-BR', { timeZone: 'America/Boa_Vista' })}
              </p>
            </div>
          </div>
        </div>



        <div className="row mb-3">
          <div className="col">
            <h6>Informações Básicas</h6>
          </div>
        </div>
        <div className="row h-100 mb-4">
          <div className="col-12 col-md-6 col-lg-6">
            <div className="form-group form-floating  mb-3">
              <input type="text" className="form-control" name="nome" value={cadastroPessoa.nome} onChange={valueInput} />
              <label for="names">Nome</label>
            </div>
          </div>
          <div className="col-6 ">
            <div className="form-group form-floating  mb-3">
              <input type="date" className="form-control" name='dataNascimento' value={cadastroPessoa.dataNascimento} onChange={valueInput} />
              <label for="names">Nascimento</label>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group form-floating  mb-3">
              <InputMask type="text" className="form-control" name='cpf' value={cadastroPessoa.cpf} onChange={valueInput} mask="999.999.999-99" />
              <label for="names">CPF</label>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            <div className="form-group form-floating  mb-3">
              <input type="text" className="form-control" name='mae' value={cadastroPessoa.mae} onChange={valueInput} />
              <label for="names">Mãe</label>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group form-floating  mb-3">
              <input type="text" className="form-control" name='pai' value={cadastroPessoa.pai} onChange={valueInput} />
              <label for="names">Pai</label>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group form-floating  mb-3">
              <InputMask type="text" className="form-control" name='telefone' value={cadastroPessoa.telefone} onChange={valueInput} mask="(99)99999-9999" />
              <label for="names">Telefone</label>
            </div>
          </div>

        </div>

        <div className="row mb-3">
          <div className="col">
            <h6>Endereço</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4 mb-3">
            <div className="form-group form-floating">
              <input type="text" className="form-control" name="logradouro" value={cadastroPessoa.logradouro} onChange={valueInput} />
              <label className="form-control-label" for="address1">Logradouro</label>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-3">
            <div className="form-group form-floating">
              <input type="text" className="form-control" name="numero" value={cadastroPessoa.numero} onChange={valueInput} />
              <label className="form-control-label" for="address2">Número</label>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-3">
            <div className="form-group form-floating">
              <input type="text" className="form-control" name="referencia" value={cadastroPessoa.referencia} onChange={valueInput} />
              <label className="form-control-label" for="address3">Referência</label>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-3">
            <div className="form-group form-floating">
              <input type="text" list="datalistOptions" className="form-control" name="estadoId" />
              <datalist id="datalistOptions">
                <option value="United Kingdom" />
                <option value="United States" selected />
                <option value="Australia" />
                <option value="India" />
                <option value="Russia" />
              </datalist>
              <label className="form-control-label" for="address4">Estado</label>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-3">
            <div className="form-group form-floating">
              <input type="text" list="datalistStates" className="form-control" name="cidadeId" />
              <datalist id="datalistStates">
                <option value="California" />
                <option value="Texas" selected />
                <option value="Florida" />
                <option value="Georgia" />
                <option value="Virginia" />
              </datalist>
              <label className="form-control-label" for="address5">Cidade</label>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-3">
            <div className="form-group form-floating">
              <input type="text" list="datalistStates" className="form-control" name="bairroId" />
              <datalist id="datalistStates">
                <option value="California" />
                <option value="Texas" selected />
                <option value="Florida" />
                <option value="Georgia" />
                <option value="Virginia" />
              </datalist>
              <label className="form-control-label" for="address5">Bairro</label>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-4">
            <div className="form-group form-floating">
              <InputMask type="text" className="form-control" name="cep" value={cadastroPessoa.cep} mask="99.999-999" onChange={valueInput} />
              <label className="form-control-label" for="address7">CEP</label>
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <h6>Informes</h6>
          </div>
        </div>
        <div className="row h-100 mb-3">
          <div className="col-12 col-md-6 col-lg-4 mb-3">
            <div className="form-group form-floating">
              <input type="text" list="datalistOptions" className="form-control" name="orcrimId" onChange={valueInput} />
              <datalist id="datalistOptions">
                <option value="United Kingdom" />
                <option value="United States" selected />
                <option value="Australia" />
                <option value="India" />
                <option value="Russia" />
              </datalist>
              <label className="form-control-label" for="address4">Orcrin</label>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-3">
            <div className="form-group form-floating">
              <input type="text" className="form-control" value={cadastroPessoa.vulgo} name="vulgo" onChange={valueInput} />
              <label className="form-control-label" for="address3">Vulgo</label>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-3">
            <div className="form-group form-floating">
              <input type="text" className="form-control" name="codOrcrim" value={cadastroPessoa.codOrcrim} onChange={valueInput} />
              <label className="form-control-label" for="address3">Placa | Cód</label>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-3">
            <div className="form-group form-floating">
              <input type="text" className="form-control" name="crimes" value={cadastroPessoa.crimes} onChange={valueInput} />
              <label className="form-control-label" for="address3">Crimes</label>
            </div>
          </div>
          <div className="col-12 ">
            <div className="form-group form-floating">
              <textarea type="text" className="form-control" name="observacao" value={cadastroPessoa.observacao} onChange={valueInput}>
              </textarea>
              <label className="form-control-label" for="address3">Anotações</label>
            </div>
          </div>


        </div>
        <div class="card shadow-sm mb-3">
          <div class="card-header border-0">
            <p>Vínculos </p>
          </div>

          <div class="card-body pt-0">
            <div class="row">
              <div class="col-auto">
                <div class="avatar-group">
                  <div class="avatar avatar-44 rounded-circle shadow-sm">
                    <img src="http://192.168.100.8:3000/img/user2.jpg" alt="" />
                  </div>
                  <div class="avatar avatar-44 rounded-circle shadow-sm">
                    <img src="http://192.168.100.8:3000/img/user3.jpg" alt="" />
                  </div>
                  <div class="avatar avatar-44 rounded-circle shadow-sm">
                    <img src="http://192.168.100.8:3000/img/user4.jpg" alt="" />
                  </div>
                  <div class="avatar avatar-44 rounded-circle shadow-sm">
                    <img src="http://192.168.100.8:3000/img/user1.jpg" alt="" />
                  </div>
                </div>
              </div>
              <div class="col align-self-center ps-0">
                <p class="text-muted small">+15<br />Vínculos</p>
              </div>
              <div class="col-auto">
                <button class="btn btn-default btn-44 shadow-sm" onclick="window.location.replace('sendmoney1.html');">
                  <i class="bi bi-plus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <h6>Alertas e Notificações</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card shadow-sm mb-4">
              <ul className="list-group list-group-flush bg-none">
                <li className="list-group-item">
                  <div className="row">
                    <div className="col-auto pr-0 align-self-center text-end">
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" checked={cadastroPessoa.mpAberto === 'on'} name="mpAberto" onChange={(e) => setCadastroPessoa({ ...cadastroPessoa, mpAberto: e.target.checked ? 'on' : 'off' })} />
                        <label className="form-check-label" for="settingscheck1"></label>
                      </div>
                    </div>
                    <div className="col ps-0">
                      <h6 className="mb-1 text-danger">MP em Aberto</h6>
                      <p className="text-muted small">Pendente de cumprimento</p>
                    </div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="row">
                    <div className="col-auto pr-0 align-self-center text-end">
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" checked={cadastroPessoa.foragido === 'on'} name="foragido" onChange={(e) => setCadastroPessoa({ ...cadastroPessoa, foragido: e.target.checked ? 'on' : 'off' })} />
                        <label className="form-check-label" for="settingscheck2"></label>
                      </div>
                    </div>
                    <div className="col ps-0">
                      <h6 className="mb-1 text-danger">Foragido</h6>
                      <p className="text-muted small">Foragido do Sistema Penitenciário</p>
                    </div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="row">
                    <div className="col-auto pr-0 align-self-center text-end">
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" checked={cadastroPessoa.suspeitoCrime === 'on'} name="suspeitoCrime" onChange={(e) => setCadastroPessoa({ ...cadastroPessoa, suspeitoCrime: e.target.checked ? 'on' : 'off' })} />
                        <label className="form-check-label" for="settingscheck3"></label>
                      </div>
                    </div>
                    <div className="col ps-0">
                      <h6 className="mb-1">Suspeito de Crimes</h6>
                      <p className="text-muted small">Possível envolvimento em Crimes</p>
                    </div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="row">
                    <div className="col-auto pr-0 align-self-center text-end">
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" checked={cadastroPessoa.cadastroX === 'on' ? 'true' : ''} name="cadastroX" onChange={(e) => setCadastroPessoa({ ...cadastroPessoa, cadastroX: e.target.checked ? 'on' : 'off' })} />
                        <label className="form-check-label" for="settingscheck4"></label>
                      </div>
                    </div>
                    <div className="col ps-0">
                      <h6 className="mb-1">Cadastro X</h6>
                      <p className="text-muted small">Colaborador</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {error && <div className='alert alert-danger mt-2 text-center'>{error}</div>}
        {success && <div className='alert alert-success mt-2 text-center'>{success}</div>}
        <div className="row h-100 ">
          <div className="col-12 mb-4">
            <button type="submit" className="btn btn-default btn-lg w-100">Atualizar</button>
          </div>
        </div>
      </form>
    </StrictMode >
  );
}

export default Cadastros;
