import React, { StrictMode } from 'react';

function Objetos() {
  return (
    <StrictMode>
    <div class="form-group form-floating mb-3">
      <input type="text" class="form-control " id="search" placeholder="Search" />
      <label class="form-control-label" for="search">Buscar Objeto</label>
      <button type="button" class="text-color-theme tooltip-btn">
        <i class="bi bi-search"></i>
      </button>
    </div>

    <div class="form-group form-floating mb-3">
      <input type="text" list="datalistStates" class="form-control" id="address5" placeholder="Select State" />
      <datalist id="datalistStates">
        <option value="California">
        </option><option value="Texas" selected="">
        </option><option value="Florida">
        </option><option value="Georgia">
        </option><option value="Virginia">
        </option></datalist>
      <label class="form-control-label" for="address5">Tipo</label>
      <button type="button" class="text-color-theme tooltip-btn">
        <i class="bi bi-search"></i>
      </button>
    </div>
    <div className="col">
      <div className="row mb-3">
        <div className="col">
          <h6 className="title">Últimos Objetos</h6>
        </div>
        <div className="col-auto">
          <a href="userlist.html" className="small">Ver Todos</a>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12">
          <swiper-container className="mySwiper" space-between="20"
            slides-per-view="4">
            <swiper-slide>
              <a href="profile.html" className="card text-center">
                <div className="card-body">
                  <figure className="avatar avatar-50 shadow-sm mb-1 rounded-10">
                    <img src="img/user4.jpg" alt="" />
                  </figure>
                  <p className="text-color-theme size-12 small">Nicolas</p>
                </div>
              </a>
            </swiper-slide>
            <swiper-slide>
              <a href="profile.html" className="card text-center">
                <div className="card-body">
                  <figure className="avatar avatar-50 shadow-sm mb-1 rounded-10">
                    <img src="img/user4.jpg" alt="" />
                  </figure>
                  <p className="text-color-theme size-12 small">Nicolas</p>
                </div>
              </a>
            </swiper-slide>
            <swiper-slide>
              <a href="profile.html" className="card text-center">
                <div className="card-body">
                  <figure className="avatar avatar-50 shadow-sm mb-1 rounded-10">
                    <img src="img/user4.jpg" alt="" />
                  </figure>
                  <p className="text-color-theme size-12 small">Nicolas</p>
                </div>
              </a>
            </swiper-slide>
            <swiper-slide>
              <a href="profile.html" className="card text-center">
                <div className="card-body">
                  <figure className="avatar avatar-50 shadow-sm mb-1 rounded-10">
                    <img src="img/user4.jpg" alt="" />
                  </figure>
                  <p className="text-color-theme size-12 small">Nicolas</p>
                </div>
              </a>
            </swiper-slide>
            <swiper-slide>
              <a href="profile.html" className="card text-center">
                <div className="card-body">
                  <figure className="avatar avatar-50 shadow-sm mb-1 rounded-10">
                    <img src="img/user4.jpg" alt="" />
                  </figure>
                  <p className="text-color-theme size-12 small">Nicolas</p>
                </div>
              </a>
            </swiper-slide>
          </swiper-container>

        </div>
      </div>
    </div>
    <div className="col">
      <div className="row mb-3">
        <div className="col  align-self-center">
          <h6 className="title">Objetos</h6>
        </div>
        <div className="col-auto">
          <button class="btn btn-44 btn-default shadow-sm">
            <i class="bi bi-plus"></i>
          </button>
        </div>
      </div>
    </div>
    <div className="row mb-4">
      <div className="col-12 ">
        <div class="card shadow-sm mb-4">
          <ul className="list-group list-group-flush bg-none">
            <li className="list-group-item">
              <div className="row">
                <div className="col-auto">
                  <div className="avatar avatar-50 shadow rounded-10 ">
                    <img src="img/company4.jpg" alt="" />
                  </div>
                </div>
                <div className="col align-self-center ps-0">
                  <p className="text-color-theme mb-0">RAIMUNDO NONATO DOS SANTOS</p>
                  <p className="text-muted size-12">PACARAIMA</p>
                </div>
                <div className="col align-self-center text-end">
                  <button class="btn btn-44 btn-light text-primary shadow-sm">
                    <i class="bi bi-pencil-square"></i>
                  </button>
                  <button class="btn btn-44 btn-light text-danger shadow-sm">
                    <i class="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            </li>

            <li className="list-group-item">
              <div className="row">
                <div className="col-auto">
                  <div className="avatar avatar-50 shadow rounded-10">
                    <img src="img/company5.png" alt="" />
                  </div>
                </div>
                <div className="col align-self-center ps-0">
                  <p className="text-color-theme mb-0">RICARDO ZANCHETA DO CARMO</p>
                  <p className="text-muted size-12">BOA VISTA</p>
                </div>
                <div className="col align-self-center text-end">
                  <p className="mb-0">18/10/1985</p>
                  <p className="text-muted size-12">N/A</p>
                </div>
              </div>
            </li>

            <li className="list-group-item">
              <div className="row">
                <div className="col-auto">
                  <div className="avatar avatar-50 shadow rounded-10">
                    <img src="img/company1.png" alt="" />
                  </div>
                </div>
                <div className="col align-self-center ps-0">
                  <p className="text-color-theme mb-0">Starbucks</p>
                  <p className="text-muted size-12">Food</p>
                </div>
                <div className="col align-self-center text-end">
                  <p className="mb-0">-18.00</p>
                  <p className="text-muted size-12">Cash</p>
                </div>
              </div>
            </li>

            <li className="list-group-item">
              <div className="row">
                <div className="col-auto">
                  <div className="avatar avatar-50 shadow rounded-10">
                    <img src="img/company3.jpg" alt="" />
                  </div>
                </div>
                <div className="col align-self-center ps-0">
                  <p className="text-color-theme mb-0">Walmart</p>
                  <p className="text-muted size-12">Clothing</p>
                </div>
                <div className="col align-self-center text-end">
                  <p className="mb-0">-105.00</p>
                  <p className="text-muted size-12">Wallet</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </StrictMode>
  );
}

export default Objetos;
