import React, { StrictMode } from 'react';

function Pessoas() {
  return (
    <StrictMode>
      <div class="col-12 col-md-4">
        <div class="card mb-4 text-center shadow-sm">
          <div class="card-body">
            <i class="avatar avatar-100 text-primary bi bi-person-bounding-box fs-1 mb-2"></i>
            <h5 class="text-muted mb-4">Scan</h5>

          </div>
          <div class="card-footer text-center">
            <button class="btn btn-default btn-lg w-100"><i className="bi bi-camera fs-1"></i></button>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col text-center">
          <h6 class="title ">Correspondências</h6>
        </div>
      </div>
      <div class="card mb-3">
        <div class="col-12 px-0">
          <ul class="list-group list-group-flush bg-none">
            <li class="list-group-item">
              <div class="row">
                <div class="col-auto">
                  <div class="form-check avatar">
                    <label for="contact11" class="avatar avatar-44 shadow-sm rounded-10">
                      <img src="img/user1.jpg" alt="" />
                    </label>
                  </div>
                </div>
                <div class="col align-self-center ps-0">
                  <p class="mb-1 text-color-theme">Nicolas Apurva</p>
                  <p class="text-muted size-12">81%</p>
                </div>
                <div class="col-auto">
                  <button class="btn btn-default btn-44 shadow-sm" onclick="window.location.replace('sendmoney2.html');">
                    <i class="bi bi-arrow-up-right-circle"></i>
                  </button>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col-auto">
                  <div class="form-check avatar">
                    <label for="contact12" class="avatar avatar-44 shadow-sm rounded-10">
                      <img src="img/user2.jpg" alt="" />
                    </label>
                  </div>
                </div>
                <div class="col align-self-center ps-0">
                  <p class="mb-1 text-color-theme">Alicia Doe</p>
                  <p class="text-muted size-12">65%</p>
                </div>
                <div class="col-auto">
                  <button class="btn btn-default btn-44 shadow-sm" onclick="window.location.replace('sendmoney2.html');">
                    <i class="bi bi-arrow-up-right-circle"></i>
                  </button>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col-auto">
                  <div class="form-check avatar">
                    <label for="contact13" class="avatar avatar-44 shadow-sm rounded-10">
                      <img src="img/user3.jpg" alt="" />
                    </label>
                  </div>
                </div>
                <div class="col align-self-center ps-0">
                  <p class="mb-1 text-color-theme">Christina Gyle</p>
                  <p class="text-muted size-12">50%</p>
                </div>
                <div class="col-auto">
                  <button class="btn btn-default btn-44 shadow-sm" onclick="window.location.replace('sendmoney2.html');">
                    <i class="bi bi-arrow-up-right-circle"></i>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </StrictMode >
  );
}

export default Pessoas;
