import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import LoginLayout from './components/LoginLayout';
import AdminLayout from './components/AdminLayout';
import Login from "./public/Login/Login";
import Home from "./private/Home/Home";
import Cadastros from './private/Cadastros/Cadastros';
import CadastroEdit from './private/Cadastros/CadastrosEdit';
import Chamados from './private/Chamados/Chamados';
import Consulta from './private/Consultas/Consulta';
import Objetos from './private/Objetos/Objetos';
import Orcrim from './private/Orcrim/Orcrim';
import Pessoas from './private/Pessoas/Pessoas';

const isAuthenticated = () => {
  const token = localStorage.getItem('token');
  return token ? true : false;
};

function Rotas() {

  return (
    <Routes>
      <Route path="/" element={<LoginLayout><Login /></LoginLayout>} />
      <Route path="/login" element={<LoginLayout><Login /></LoginLayout>} />
      <Route path="/home" element={isAuthenticated() ? <AdminLayout ><Home /></AdminLayout> : <Navigate to="/login" />} />
      <Route path="/cadastros" element={isAuthenticated() ? <AdminLayout title='CADASTROS'><Cadastros /></AdminLayout> : <Navigate to="/login" />} />
      <Route path="/cadastro-edit/:id" element={isAuthenticated() ? <AdminLayout title='ATUALIZAR CADASTRO'><CadastroEdit /></AdminLayout> : <Navigate to="/login" />} />
      <Route path="/chamados" element={isAuthenticated() ? <AdminLayout title='CHAMADOS'><Chamados /></AdminLayout> : <Navigate to="/login" />} />
      <Route path="/consulta" element={isAuthenticated() ? <AdminLayout title='CONSULTA'><Consulta /></AdminLayout> : <Navigate to="/login" />} />
      <Route path="/objetos" element={isAuthenticated() ? <AdminLayout title='OBJETOS'><Objetos /></AdminLayout> : <Navigate to="/login" />} />
      <Route path="/orcrim" element={isAuthenticated() ? <AdminLayout title='ORCRIM'><Orcrim /></AdminLayout> : <Navigate to="/login" />} />
      <Route path="/pessoas" element={isAuthenticated() ? <AdminLayout title='PESSOAS'><Pessoas /></AdminLayout> : <Navigate to="/login" />} />
    </Routes>
  )
}
export default Rotas;